import type { CentraSelectionItem } from "@frend-digital/centra/schemas/selection/selectionProductSchema";
import type { CentraSelection } from "@frend-digital/centra/schemas/selection/selectionSchema";

import {
  generateCartSummary,
  getFirstImage,
  getSelectionItemCategory,
  getSelectionItemId,
  getSelectionItemLine,
  getSelectionItemName,
  getSelectionItemPriceEach,
  getSelectionItemPriceEachAsNumber,
  getSelectionItemPriceEachBeforeDiscount,
  getSelectionItemQuantity,
  getSelectionItemSize,
  getSelectionItemSku,
  getSelectionItemUri,
  getSelectionItemVariantName,
} from "../selectionAtoms";

const generateCartItem = (item: CentraSelectionItem) => {
  const name = getSelectionItemName(item) ?? "";
  const uri = getSelectionItemUri(item) ?? "";
  const variant = getSelectionItemVariantName(item) ?? "";
  const sku = getSelectionItemSku(item) ?? "";
  const size = getSelectionItemSize(item) ?? "";
  const id = getSelectionItemLine(item) ?? "";
  const itemId = getSelectionItemId(item) ?? "";
  const priceAsNumber = getSelectionItemPriceEachAsNumber(item);
  const price = getSelectionItemPriceEach(item);
  const discountedPrice = getSelectionItemPriceEachBeforeDiscount(item);
  const quantity = getSelectionItemQuantity(item) ?? 0;
  const category = getSelectionItemCategory(item) ?? "";
  // const media = getFirstImage(item);
  const media = {
    standard: getFirstImage(item, "standard"),
    full: getFirstImage(item, "full"),
  };
  const line = item.line ?? "";
  return {
    name,
    uri,
    variant,
    size,
    id,
    price,
    quantity,
    media,
    priceAsNumber,
    discountedPrice,
    itemId,
    line,
    sku,
    category,
  };
};

const formatSelection = (data: CentraSelection) => {
  const formattedItems = data.selection?.items?.map((item) =>
    generateCartItem(item)
  );
  const summary = generateCartSummary(data);
  const formattedData = {
    ...data,
    selection: {
      ...data.selection,
      items: formattedItems,
      summary,
    },
  };
  return formattedData;
};

export type FormattedSelection = ReturnType<typeof formatSelection>;

export default formatSelection;
